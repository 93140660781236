<template>
  <div class="container-fluid">
      <div class="row">
      <div class="col-12">
        <div>
        <video width="100%" height="auto" loop muted autoplay>
          <source src="/video/aboutttitle.mp4" type="video/mp4" >
          Your browser does not support the video tag.
        </video>
        </div>
      </div>
  </div> 


    <div class="row">
        <div class="col col-sm-12  col-md-10 offset-md-1 col-lg-8 offset-lg-2">

          <div id="textcontainer" style="margin-top:30px;">
            <p>
            Steve grew up in Takoma Park, Maryland, just outside his birthplace of Washington, 
            DC. By age 10, Francis worked summer jobs to ease the financial burden on his mother, 
            Brenda Wilson. His two older brothers and younger sister grew up together with their 
            mother and stepfather.
            </p>
 
            <p style="padding-left:20px; margin-top:40px; margin-bottom:40px; margin-left:20px;  border-left:8px solid #af0000">
                “I know I’ve had it rough, especially being from around here. 
                Just seeing a lot of things happen—good and bad—for people, 
                that really made me open my eyes and see which way I wanted to 
                go and what I wanted to do with my life. It showed me what type
                of a role model I wanted to be for my little cousins and my little 
                sister and my family and my friends, too, even the ones that are older than me. 
                Since I’ve been going to school and doing my thing, they’ve been changing themselves. 
                Just so it can rub off on other people, that makes me feel better.”
            </p>
           <img src="images/stevefrancis-banner.jpg" class="img-fluid" style="padding-bottom:20px;"> 
            <p>
              The Takoma Park Boys & Girls Club provided Steve the opportunity to play athletics 
              at an early age. Growing up he idolized Randall Cunningham and dreamed of playing 
              in the NFL. It was an old fire station and local playground courts where 
              his basketball talents would develop. He was academically ineligible to play basketball 
              his freshman year in high school. His sophomore year, he grew from 5’3” to 5’9” 
              and made the varsity squad, averaging 10 minutes per game. An ankle injury during 
              a pre-season pick-up game prevented him from playing his junior season, and 
              tragically when his mother passed away his senior year, he dropped out of school.
            </p>


            <p style="padding-left:20px; margin-top:40px; margin-bottom:40px; margin-left:20px;  border-left:8px solid #af0000">
              “I was at a point where I didn’t know what to do following the loss of my mother; 
              I didn’t know which way to go or what to do. So I just went back to playing ball… 
              To get through that is something you’ve really got to want to do, something you need 
              your friends and family to help you with. You have to have that support. But it’s in my 
              nature. That’s what God put me here to do. To be a role model, 
              especially for those kids who have it tough and maybe don’t have as many opportunities. 
              I’m here to show them that there is always a way to get through it.”
            </p>
               <img src="images/stevefrancis-dunk.jpg" class="float-left img-fluid" style="padding:10px; margin-bottom:30px;" >
            <p>
             Support and guidance from his family and friends helped Steve get back on the right 
             track. He earned his GED and enrolled at San Jacinto Junior College in Texas. 
             The following year he attended Allegany Community College in Maryland and earned 
             his Associates Arts Degree. Francis led the basketball teams at both of these 
             schools to the NJCAA final. This success landed him at the University of Maryland, 
             and onto to the 1999 NBA Draft, where he was the Second Overall Pick. In 2002, Steve signed a 
             maximum contract extension with the Houston Rockets. He has continued his NBA Career
              with the Orlando Magic, New York Knicks and again with the Houston Rockets.
            </p>

            <p style="padding-left:20px; margin-top:40px; margin-bottom:40px; margin-left:20px;  border-left:8px solid #af0000">
              “I used to think about all the people who doubted them (Rosa Parks, Martin Luther King, Jr.,
              Thurgood Marshall and Harriet Tubman) and all the hard work they had to put in to get 
              where they got. It teaches youth that you can overcome anything, and that if you really 
              believe in yourself, there’s no telling what heights you might reach.”
            </p>

            <p>
              After undergoing a lengthy rehabilitation process on his knee and quadricep following surgery, 
              Steve retired from the NBA but continues to commit himself to the community with his work through The Steve Francis 
              Foundation and to the game of basketball as the owner of the newly launched 
              professional basketball team The Houston Push of The Basketball League
            </p>

            <p>
              After undergoing a lengthy rehabilitation process on his knee and quadricep following surgery, 
              Steve retired from the NBA but continues to commit himself to the community with his work through The Steve Francis 
              Foundation and to the game of basketball as the owner of the newly launched 
              professional basketball team The Houston Push of The Basketball League
            </p>

            
            <p style="padding-left:20px; margin-top:40px; margin-bottom:40px;  margin-left:20px;  border-left:8px solid #af0000">
              “The TBL is one thing as well as the Steve Francis Foundation, which I’ve run since 1999. 
              All these things and my NBA career shaped me as person, and despite basketball being the 
              biggest part of my life other than family and having kids, I’m just thankful to be in this 
              position in my life to give back and do it within the game of basketball.”
            </p>

            <p>
              Above all else, Steve’s pride and joy are his two children, Shailyn Francis and Steven Francis Jr.
            </p>
          </div> 
          <!-- end text container --> 
        </div>
     </div>

</div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  created() {
  },
  computed: {
  },
  methods: {


  }
};
</script>

<style>
</style>