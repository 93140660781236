import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import  'firebase/storage'
import  'firebase/firestore'

// firebase init - add your own config here
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FB_API_KEY,
  authDomain: process.env.VUE_APP_FB_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FB_DATABASE_URL,
  projectId: process.env.VUE_APP_FB_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FB_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FB_MESSAGING_SENDER_ID
};

firebase.initializeApp(firebaseConfig)

// utils
const db = firebase.database()
const fs = firebase.firestore()
const auth = firebase.auth()
const storage = firebase.storage()
// Articles collection reference

const articlesCollection = db.ref('articles')


// export utils/refs
export {
  db,
  fs,
  auth,
  articlesCollection,
  storage 
}