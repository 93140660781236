import moment from 'moment'
import Vue from 'vue'
import Vuex from 'vuex'
import * as fb from '../firebase'
import router from '../router/index'

Vue.use(Vuex)
// realtime database
fb.articlesCollection.on('value', (snapshot) => {
  let articlesArray = []

  snapshot.forEach(doc => {
    let article = doc.val()
    article .id = doc.key
    articlesArray.push(article)
  })

  store.commit('SET_ARTICLES', articlesArray)
})

fb.auth.onAuthStateChanged((user) => {
  if (user) {
    store.commit('setUserProfile', user)
  } else {
    let tempuser = {uid : null}
    store.commit('setUserProfile', tempuser)
  }
  

});


const store = new Vuex.Store({

  state() {
    return{
    userProfile: {},
    articles: [],
    currentarticle: {}
    }
  },


  mutations: { //takes an object that you can define methods to change state - automatically gets current state as first argument
    
      setUserProfile(state, val) {
        state.userProfile = val
      },

      SET_ARTICLES(state, payload) {
        state.articles = payload;
      },

      ADD_ARTICLE(state, payload) {
        state.articles.push(payload);
      },

      UPDATE_ARTICLE(state, article) {
      console.log('UPDATE ARTICLE', state,article)
      },

      DELETE_ARTICLE(state, article) {
          const index = state.articles.indexOf(article);
          if (index > -1) {
          state.articles.splice(index, 1);
          }
      },

  },



  actions: {
  //// CRUD /////////////////////////////////////////////////////////////////////// 
  // create
  async create(_, { vm, data, target }) {
      data.created = moment().toISOString(),
       fb.db.ref(target).push(data)
      .catch((error) => {
      console.log(error);
   })
    const payload = {data};      
    store.dispatch("ADD_ARTICLE", { vm: vm, payload: payload });
    },


    async sendmail(vm, to, message){
      console.log('to', to);
      console.log('vm', vm);
      console.log('message', message);
      fb.fs.collection('mail').add({
        to: to.to,
        message: to.message,
      })
    },

 // delete
    async delete_article(context, article) {
      await fb.db.ref('articles/' + article.id).remove()
      context.commit('DELETE_ARTICLE', article)
    },

    async updateArticle(context, article) {
      console.log('article', article.id, article);
      await fb.articlesCollection.child(`${article.id}`).update(article)
     // context.commit('UPDATE_ARTICLE', article)

    },

//// AUTH ///////////////////////////////////////////////////////////////////////
    async login({ dispatch }, form) {
      // sign user in
      const { user } = await fb.auth.signInWithEmailAndPassword(form.email, form.password)
      // fetch user profile and set in state
      dispatch('fetchUserProfile', user)
    },

    async fetchUserProfile({ commit }, user) {
      // set user profile in state
      commit('setUserProfile', user)
      // change route to dashboard
      if (router.currentRoute.path === '/login') {
        router.push('/')
      }
    },

    async logout({ commit }) {
      // log user out
      await fb.auth.signOut()
      // clear user data from state
      commit('setUserProfile', {})
      // redirect to login view
      router.push('/login')
    },

  },

  getters: {
    allarticles (state){  
      return state.articles
    },
    userProfile (state){  
      return state.userProfile
    }

}
})

export default store
