<template>
  <div id="app" >
    <Header></Header>
    <router-view />
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "./views/Header";
import Footer from "./views/Footer";
//import { mapState } from "vuex";

export default {
  components: {
    Header,
    Footer
  },
  //computed: {
  //  ...mapState(["userProfile"]),
  //  showNav() {
  //    return Object.keys(this.userProfile).length > 1;
  //  },
//  },
};
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>

