<template>
  <div class="container-fluid" style="background:#f5f5f5;">

<span class="float-left d-none d-xs-none d-sm-none d-md-block" style="color:#af0000; font-weight:bold; padding-top:4px;font-size:13px;">
  <em>Come Original</em>
  </span>

<div class="row" style="height:30px; padding-top:5px;">
            <div class="col-12" > 
          
              
           <router-link v-if="user.uid == null"
            class="float-right btn btn-small btn-outline-danger"
            style="padding:2px; width:70px; margin-left:10px;  font-size:10px;"
            tag="button"  
            :to="{ name: 'Login'}"
            >
            login
            </router-link> 

            <button v-if="user.uid != null"
            class="float-right btn btn-small btn-outline-danger"
            style="padding:2px; width:70px; margin-left:10px;  font-size:10px;"
            tag="button"  
            @click="logout(user)"
            >
            logout
            </button> 


             <router-link v-if="user.uid != null"
               class="float-right btn btn-small btn-outline-danger"
               style="padding:2px; width:70px; margin-left:10px;  font-size:10px;"
            tag="button"  
            :to="{ name: 'Admin'}"
            >
            admin
            </router-link> 

                <p style="font-size:13px" class="float-right" v-if="user.email" >{{user.email}}</p>
</div>
</div>

     <div class="row">



               <div class="col-12" > 
                  <div style="background-image: url('images/courtbackground.jpg'); height:200px;">
          
           <div class="row"> 

            <div class="col-9" style="padding-left:10px;padding-top:15px; padding-bottom:30px;">
              <img style="margin-left:30px;" class="img-fluid" src="images/slfempdriplogo.png">
            </div>

            <div class="col-3">
    
                  
                
            </div>

          </div>

        </div>

      </div>

      <div class="col-12"> 
            <b-navbar toggleable="lg" type="dark" style="  background: linear-gradient(45deg, #760000, #ff0000;">
                <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

                <b-collapse id="nav-collapse" is-nav>
                  <b-navbar-nav>
                    <b-nav-item href="/"><b>HOME</b></b-nav-item>
                    <b-nav-item href="#/news"><b>NEWS</b></b-nav-item>
                  <!--
                    <b-nav-item-dropdown text="PROGRAMS" style="font-weight:bold">
                    <b-dropdown-item href="#/scholarshipprogram">The Brenda Wilson Scholarship Program</b-dropdown-item>
                    <b-dropdown-item href="#/calvinavantaward">The Calvin Avant Award</b-dropdown-item>
                    <b-dropdown-item href="#/dcwarriors">DC Warriors</b-dropdown-item>
                    <b-dropdown-item href="#/houstonpush">Houston Push</b-dropdown-item>
                    </b-nav-item-dropdown>
                
                    <b-nav-item href="#/about"><b>ABOUT</b></b-nav-item>
                    <b-nav-item href="#/donate"><b>DONATE</b></b-nav-item>
                    <b-nav-item href="#/contact"><b>CONTACT</b></b-nav-item> -->

                  </b-navbar-nav>

            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">

                  <!-- Twitter --> 
                  <div class="float-right px-3" >
                    <a target="_blank" href="https://twitter.com/slfemp">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#fff" class="bi bi-twitter" viewBox="0 0 16 16">
                        <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
                      </svg>
                      </a> 
                </div>
                  <!-- Instagram --> 
                  <div class="float-right px-3" > 
                    <a target="_blank" href="http://instagram.com/slfemp">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#fff" class="bi bi-instagram" viewBox="0 0 16 16">
                        <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/>
                      </svg>
                    </a>
                </div>

                  <!-- Facebook --> 
                  <div class="float-right px-3" > 
                    <a target="_blank"  href="http://www.facebook.com/slfemp713">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#fff" class="bi bi-facebook" viewBox="0 0 16 16">
                      <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
                      </svg>
                    </a>
                  </div>

            </b-navbar-nav>
          </b-collapse>
        </b-navbar>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  

computed: {
     user() {
      return this.$store.getters["userProfile"];
    }
  },

  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
  },
};
</script>

<style>
</style>