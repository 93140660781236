import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import { auth } from '../firebase'
import Articles from '../views/Articles.vue'
import Createarticle from '../views/Createarticle.vue'
import Editarticle from '../views/Editarticle.vue'
import Admin from '../views/Admin.vue'
import About from '../views/About.vue'
import Content from '../views/Content.vue'
import Contact from '../views/Contact.vue'
import News from '../views/News.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: false
    }
  },

  {
    path: '/home',
    name: 'Homepage',
    component: Home,
    meta: {
      requiresAuth: false
    }
  },

  {
    path: '/about',
    name: 'About',
    component: About,
    meta: {
      requiresAuth: false
    }
  },

  {
    path: '/content/:id/:category',
    name: 'Content',
    component: Content,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: {
      requiresAuth: false
    }
  },

  {
    path: '/news',
    name: 'News',
    component: News,
    meta: {
      requiresAuth: false
    }
  },

  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/login',
    name: 'Login',
    component: () => import( /* webpackChunkName: "login" */ '../views/Login.vue')
  },

  {
    path: '/articles',
    name: 'Articles',
    component: Articles,
    meta: {
      requiresAuth: false
    }
  },

  {
    path: '/createarticle',
    name: 'Createarticle',
    component: Createarticle,
    meta: {
      requiresAuth: false
    }
  },

  {
    path: '/editarticle/:id',
    name: 'Editarticle',
    component: Editarticle,
    meta: {
      requiresAuth: false
    }
  },

]

const router = new VueRouter({
  routes
})

// // navigation guard to check for logged in users
router.beforeEach((to, _, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)

  if (requiresAuth && !auth.currentUser) {
    next('/login')
  } else {
    next()
  }
})

export default router
