<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col col-sm-12  col-md-10 offset-md-1 ">
        <div class="row">
            
             <div class="col-12" style="margin-top:30px; margin-bottom:20px; height:80px;" >
                <div style="border:1px solid #760000; height:60px; overflow:hidden;">
                   
                    <h3 
                        style="font-weight:300; padding:10px; padding-top:16px; color:#760000;">
                        {{currentarticle.title}}
                   
                        </h3>
                        <p>     {{ moment(currentarticle.created).calendar()}}</p>

 
               

                </div> 
             </div> 

              <div class="col-lg-8">
               <img class="img-fluid" v-if="currentarticle.banner" :src="currentarticle.banner">
                <div v-html="currentarticle.content"></div>
                <div style="margin-top:100px;">
                  <router-link  class="btn btn-outline-danger" style="margin-top:14px; margin-right:20px;"
                  tag="button" 
                  :to="{ name: 'Editarticle', params: {id : currentarticle.id, category: currentarticle.category}}"
                  >
                  edit
                  </router-link> 
                </div>
              </div>   


                <div class="col-lg-4">
                  <h4 v-if="currentarticle.category == 'scholarship'"> Scholarship Highlights</h4>
                      <h4 v-if="currentarticle.category == 'news'"> SFF News</h4>
                          <h4 v-if="currentarticle.category == 'houstonpush'">Houston Push Highlights</h4>
                              <h4 v-if="currentarticle.category == 'calvinavant'">Calvin Avant Highlights</h4>
                                  <h4 v-if="currentarticle.category == 'dcwarriors'">DC Warriors Highlights</h4>
                                  

                <div  v-for="article in allarticles" :key="article.Id">
                  <div class="sidebut" style="margin-bottom:3px;"> 
                  
                                        
              <router-link :to="{ name: 'Content', params: {id : article.id, category : article.category}}">
                <table>
                  <tr>
                    <td>   <img :src="article.thumbnail" style="width:60px; padding:5px;" ></td>
                    <td style="padding:10px;"><span style="color:#999; font-size:13px;">{{article.title}}</span></td>
                  </tr>
                </table>
              </router-link> 

                     
                     </div>
                </div>
              </div>  


          </div> 
       </div>  
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
    };
  },

computed: {
     currentarticle() {
      const articles = this.$store.getters["allarticles"];
      return articles.filter((o) => o.id == this.$route.params.id)[0];
    },

      allarticles() {
      const articles = this.$store.getters["allarticles"];
      return articles.filter((o) => o.category == this.$route.params.category);
    },

     user() {
      return this.$store.getters["userProfile"];
    }

  },


}

</script>

<style>
.sidebut {cursor:pointer; width:100%; border-bottom:1px solid #ccc;  background:#fff }
.sidebut :hover {
  background-color:#f9f9f9;
}
</style>

