<template>
  <div class="container-fluid">
  <div class="row">
      <div class="col-12">
        <div>
        <video width="100%" height="auto" loop muted autoplay>
          <source src="/video/contacttitle.mp4" type="video/mp4" >
          Your browser does not support the video tag.
        </video>
        </div>
      </div>

      
          <div class="col col-sm-12  col-lg-10 offset-lg-1" style="padding:20px; margin-top:40px; margin-bottom:40px;   background: linear-gradient(45deg, #f5f5f5, #f1f1f1;">

              <div class="row"> 
                <div class="col-12">
               <h3>We would love to hear from you. </h3>
                <p>Please use the form below. If you are requesting an autograph from Steve Francis, please include your mailing address.</p>
                <b>For autographed memorabilla </b>
               <p>You may send in your own photos or memorabilia for Steve to sign to: <br>
               The Steve Francis Foundation, c/o Carrie Potter Group, 1302 Waugh Drive, Suite 384, Houston, TX  77019.</p>
              </div>
             
                   <div class="col-md-6 col-sm-12">
                  <h6>Full Name</h6>
                  <input type="text" class="form-control" v-model="message.fullname" placeholder="Full Name">
                  </div>

                    <div class="col-md-6 col-sm-12">
                   <h6>Email</h6>
                  <input type="text" class="form-control" v-model="message.email" placeholder="Email">
                  </div>

                           <div class="col-md-6 col-sm-12">
                   <h6>Phone</h6>
                  <input type="text" class="form-control" v-model="message.phone" placeholder="Phone">
                  </div>


                      <div class="col-12">
                   <h6>Message</h6>
                     <b-form-textarea
                        v-model="message.description"
                        placeholder="type in your message here"
                        rows="1"
                        max-rows="2"
                      ></b-form-textarea>
                      </div> 
                    <div class="col-12">
                     <button style="margin-top:20px;" class="btn btn-danger" @click="onSubmit(message)">Send</button>
                    </div> 
             </div>
          </div>

  </div> 

  </div>
</template>

<script>
export default {
  data() {
    return {
      message: {
        subject : 'message from The Steve Francis Foundation Website',
        html: 'hello'
        }
    };
  },

  methods: {
onSubmit(message){
  const messagebody = "Name: "+message.fullname+"<br> Email: "+message.email+"<br> Phone: "+message.phone+"<br> <br> Message: "+message.description ;
      this.$store.dispatch("sendmail",{ vm: this, to: 'sf3foundation@gmail.com', message: {subject : 'Message from SteveFrancisFoundation.com Contact Page', html : messagebody }  });
console.log('mail sent');
   this.$router.replace("/");

}

  }
};
</script>

<style>
h6 {padding-top:20px; padding-bottom:10px;}
</style>