<template>
 <div class="container-fluid" style="margin-bottom:60px; padding-bottom:60px; background: #fff">
  <div class="row">
      <div class="col-12">
        <div>
        <video width="100%" height="auto" loop muted autoplay>
          <source src="/video/newstitle1.mp4" type="video/mp4" >
          Your browser does not support the video tag.
        </video>
        </div>
      </div>
  </div> 

          <div class="col col-sm-12 col-lg-10 offset-lg-1">

              <div class="row"> 
              <div class="col-sm-12 col-md-6 col-lg-3" v-for="article in articles" :key="article.Id" style="margin-top:20px;">
                    <div class="card" style="width: 100%;">
                    <div style="max-height:400px; overflow:hidden"> <img :src="article.thumbnail" class="card-img-top" alt=""></div>
                    <div class="card-body">
                      <h5 class="card-title" style="font-weight:bold">{{article.title}}</h5>
                            <p style="font-size:11px"><em>Published:  {{ moment(article.created).calendar()}}</em></p>
                      <p class="card-text">
                        {{article.description}}</p>
                        <router-link 
                        tag="button"  
                        class="btn btn-outline-secondary btn-sm" 
                          :to="{ name: 'Content', params: {id : article.id, category : article.category}}"
                        >
                        View
                        </router-link> 
                    </div>
                    </div>
                  </div>
              </div>
            </div>
           
        </div>

</template>
<script>

export default {
  data() {
    return {
    };
  },


computed: {
     articles() {
      const articles = this.$store.getters["allarticles"];
      return articles.filter((o) => o.category =='news');
    }
  }

}
</script>

<style>
</style>
