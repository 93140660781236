<template>
  <div class="container-fluid" style="background:#f5f5f5">
    <div class="row">

      
        <div class="row" style="background:#f5f5f5; margin-top:-22px;padding:16px;">
<div class="col-12">
  <img src="images/slfempgoodlookinout.jpg" class="img-fluid">
</div>
  
 <div class="col-12">
     <div style="padding:30px;   padding:10px; ">
  <div class="col col-sm-12 " style="padding:20px; margin-top:40px;   background: linear-gradient(45deg, #f5f5f5, #f1f1f1;">

              <div class="row"> 
              <div class="col-sm-12 col-md-6 col-lg-3" v-for="article in articles" :key="article.Id">
                    <div class="card" style="width: 100%;">
                    <div style="height:300px; overflow:hidden"> <img :src="article.thumbnail" class="card-img-top" alt=""></div>
                    <div class="card-body">
                      <h5 class="card-title" style="font-weight:bold">{{article.title}}</h5>
                        <p style="font-size:11px"><em>Published:  {{ moment(article.created).calendar()}}</em></p>
                      
                      <p class="card-text" style="height:50px;">
                        {{article.description}}</p>
                        <router-link 
                        tag="button"  
                        class="btn btn-outline-secondary btn-sm" 
                          :to="{ name: 'Content', params: {id : article.id, category : article.category}}"
                        >
                        View
                        </router-link>
                    </div>
                    </div>
                  </div>
              </div>
        </div>
  </div>  
    </div>  


  </div>
</div>
</div>

</template>

<script>

// import Videobanner from "./Videobanner";

export default {

  components: {
    // Videobanner
  },

  data() {
    return {
    };
  },

computed: {
     articles() {
      const articles = this.$store.getters["allarticles"];
      console.log('article',articles);
      return articles.filter((o) => o.frontpage == true);
    }
  }

};
</script>
