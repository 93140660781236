<template>
  <div class="container-fluid" style="margin-bottom:100px;">
    <div class="row">
    <div class="col col-sm-12  col-md-10 offset-md-1 ">
     
          <div class="row">
           
            <div class="col-8" style="margin-top:20px; margin-bottom:20px;">
              <h3 >Create Content</h3>
              <p style="font-size:13px" ><b>Welcome: </b>{{user.email}}</p>
              </div>     
            
            <div class="col-4" style="margin-top:20px; margin-bottom:20px;" >
             <router-link 
            tag="button"  
            style="width:100px"
            class="btn btn-outline-danger float-right" 
            :to="{ name: 'Admin'}"
            >
            Cancel
            </router-link> 

              <button style="margin-right:10px;" class="btn btn-danger float-right" @click="onSubmit">Save</button>
              

          </div>


        <div class="col-12 col-md-8">
     <div class="row">
          <div class="col-12" style="margin-bottom:30px;">
    <b-form-checkbox v-model="article.published " class="float-left" style="margin-right:20px;" name="check-button" switch>
      Publish 
    </b-form-checkbox>

        <b-form-checkbox v-model="article.frontpage " class="float-left" name="check-button" switch>
      Front Page
    </b-form-checkbox>
    </div>
  </div>
  
                  <div>
                  <b>Title</b>
                  <input type="text" class="form-control" v-model="article.title" placeholder="give your content a title">
                  </div>
                        <div style="margin-top:20px; ">
                   <b>Description<em style="color:#999; font-size:13px;"> optional</em></b>
                     <b-form-textarea
                        v-model="article.description"
                        rows="1"
                        max-rows="2"
                      ></b-form-textarea>
                      </div> 

                <div style="margin-top:20px; margin-bottom:20px;">
                  <b>Content Body</b>
                 <!-- <vue-editor v-model="article.content" /> -->
                   <vue-editor :editorOptions="editorSettings"  useCustomImageHandler @image-added="onfilepicked" v-model="article.content"></vue-editor>
              
                </div>

        </div> <!-- end right side-->

        <div class="col-12  col-md-4">
            <b>Category:</b> <em>Select a category</em>
            <select class="custom-select" v-model="article.category" >
            <option value="news">News</option>
            <option value="scholarship">Scholarship</option>
            <option value="calvinavant">Calvin Avant</option>
            <option value="dcwarriors">DC Warriors</option>
            <option value="houstonpush">Houston Push</option>
            </select>
            
            <div style="margin-top:20px;">
              <b>Thumbnail:</b> <em>600px w x 400px h 72dpi</em>
                 <button @click="removebanner()" v-if="article.thumbnail"
                 class="btn btn-outline-danger float-right" 
                 style="position: relative; top:33px;">remove file</button>
       
              <fileupload @sendfile="recievefile" :target="'thumbnail'" ></fileupload>
              <img class="img-fluid" :src="article.thumbnail" v-bind:key="changenum">
            </div>
 
            <div style="margin-top:20px;">
              <b>Banner:</b> <em>2000px w x 300px h 72dpi</em> 
                 <button @click="removebanner()" 
                 class="btn btn-outline-danger float-right" 
                  v-if="article.banner"
                 style="position: relative; top:33px;">remove file</button>
       
              <fileupload @sendfile="recievefile" :target="'banner'" ></fileupload>
                <img class="img-fluid" :src="article.banner"  v-bind:key="changenum">
            </div>

        </div>

  </div>
  </div>
  </div>

  </div>
</template>
<script>

// import { VueEditor } from "vue2-editor";
import { VueEditor, Quill } from "vue2-editor";
import Fileupload from "../components/Fileupload";
import htmlEditButton from "quill-html-edit-button";
const ImageResize = require('quill-image-resize-vue').default
import * as fb from '../firebase';
Quill.register('modules/imageResize', ImageResize)
Quill.register('modules/htmlEditButton', htmlEditButton)

export default {
components: {
    VueEditor,
  Fileupload
},

  data() {
    return {
      article: {
      title : "", 
      content : '',
      category : 'Select Category',
      published: 1,
      },
      changenum: 1,
      editorSettings: {
          modules: {
          imageResize: {},
          htmlEditButton: {}
          }
        },
    };
  },


computed: {
    user() {
      return this.$store.getters["userProfile"];
    }
  },

  methods: {
removebanner(){
this.currentarticle.banner = ""
},

removethumbnail(){
this.currentarticle.thumbnail = ""
},


 onfilepicked(file, Editor, cursorLocation, resetUploader){
      console.log('file',file)
      const files = file
      this.filename = files.name
       
       if (this.filename.lastIndexOf('.') <= 0) {
        return alert('Please select a valid file')
        }

        const fileReader = new FileReader()
        fileReader.addEventListener('load', () => {
        })

        fileReader.readAsDataURL(files)
        this.image = files
        const filename = this.image.name
         return fb.storage.ref(filename).put(this.image)
         .then(fileData => {
           fileData.ref.getDownloadURL().then(function(url) {
           return url
            }).then(url => {
              // console.log(url);
              Editor.insertEmbed(cursorLocation, "image", url);
              resetUploader();
              // console.log('uploade file url', url);
              this.imageurl = url

              this.recievefile(url, this.target);
     })
    })
      .catch((error) => {
        console.log(error);
    })
    
  },
    recievefile(newfile, target){
      console.log('newfile',newfile);
      this.article[target] = newfile;
      this.changenum++ 

    },

    recievetext(val){
      console.log(val);
      this.article.content = val;
      console.log('this.article.content',this.article.content );
    },

    onSubmit() {
      console.log('submit', this.article);
      this.$store.dispatch("create", { vm: this, data: this.article, target: 'articles'});
      this.$router.replace("/articles");
    },


  },
};
</script>

<style>

  .input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 200px;
    position: absolute;
    cursor: pointer;
  }

</style>
