<template>
  <div class="container-fluid" style="margin-top:100px;">
    
   <!-- <div class="row" style="padding-top:50px; border-top:1px solid #760000; margin-bottom:50px;" >
      <div class="col-sm-6 col-lg-2" >
          <img class="mx-auto d-block" style="margin-top:20px;" src="images/houstonpushsmalllogo.png"> 
      </div>

      <div class="col-sm-6 col-lg-2" >
               <img class="mx-auto d-block" style="margin-top:20px;"  src="images/brendawilsonsmalllogo.png"> 
      </div>
       
      <div class="col-sm-6 col-lg-2" >
               <img class="mx-auto d-block" style="margin-top:20px;" src="images/calvinavantsmalllogo.png"> 
      </div>
       
      <div class="col-sm-6 col-lg-2" >
               <img class="mx-auto d-block" style="margin-top:20px;" src="images/dcwarriorssmalllogo.png"> 
      </div>
       
      <div class="col-sm-6 col-lg-2" >
               <img class="mx-auto d-block" style="margin-top:20px;" src="images/carriesmalllogo.png"> 
      </div>
       
      <div class="col-sm-6 col-lg-2" >
               <img class="mx-auto d-block" style="margin-top:20px;" src="images/sffsmalllogo.png"> 
      </div> -->
        
 


    <div class="row">
        <div class="col col-12" >
          <div   style="background:#760000; min-height:300px; padding:30px;">
          <div class="row"> 
         
          <div class="col-md-4" style="color:#fff"> 
           <h6>SLFEMP</h6>
          <p>713.205.0548</p>
          </div>
         
         <div  class="col-md-4"> 

          </div>
           
           <div  class="col-md-4"> 
           
          </div>

          </div>
          </div>
        </div>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  created() {""
  },
  computed: {
  },
  methods: {


  }
};
</script>

<style>
</style>