<template>
  <div class="container-fluid">
    <div class="row">
           <div class="col-12">
             <articles></articles>
          </div>
    </div>
  </div>
</template>

<script>
import Articles from "./Articles";

export default {

components: {
     Articles
   },

  methods: {

  }
};
</script>

<style>
.adminbutton {
  background:#f5f5f5; 
  padding:10px; 
  cursor:pointer;
  margin-top:1px;
}

.adminbutton:hover {
  background:#f1f1f1;

}
</style>